<template>
	<section id="partners">
		<TopBanner>
			<img src="../../assets/partners/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/partners/title.png" class="page-title" width="595">
		<section1 id="section1" />
		<section2 id="section2" />
		<section3 id="section3" />
		<Sponsor id="sponsor" />
		<Message id="Message" />
		<section4 id="section4" />
	</section>
</template>

<script>
import section1 from "../../components/partners/section1.vue";
import section2 from "../../components/partners/section2.vue";
import section3 from "../../components/partners/section3.vue";
import section4 from "../../components/partners/section4.vue";
import Sponsor from "../../components/partners/Sponsor.vue";
import Message from "../../components/partners/Message.vue";
export default {
	components: {
		section1,
		section2,
		section3,
		section4,
		Sponsor,
		Message,
	},
}
</script>