<template>
	<nav>
		<desktop v-if="$root.rwd < 2" :list="list" />
		<mobile v-else :list="list" />
	</nav>
</template>

<script>
import Desktop from "./default-desktop.vue";
import Mobile from "./default-mobile.vue";
export default {
	components: {
		Desktop,
		Mobile,
	},
	data() {
		return {
			list: [
				{
					path: "/about",
					label: "關於協會",
				},
				{
					path: "/news",
					label: "最新消息",
				},
				[
					{
						label: "協會服務",
					},
					{
						path: "/speech-workshop",
						label: "服務預約意願蒐集表",
					},
					{
						path: "/dfc-school",
						label: "DFC 種子學校",
					},
					{
						path: "/dfc-challenge",
						label: "DFC 挑戰與分享季",
					},
					{
						path: "/dfc-teacher",
						label: "DFC 種子教師",
					},
					{
						path: "/resources",
						label: "教學資源",
					},
				],
				{
					href: "https://medium.com/design-for-change-taiwan",
					label: "部落格",
				},
				{
					path: "/partners",
					label: "成為共益夥伴",
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
nav {
	background: #fff;
	transition: 0.5s;
	z-index: 99;
	padding-left: 1em;
	color: #5b5b5b;

	&.shadow {
		box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.3);
	}
}
</style>
