<template>
	<ContentBlock clip style="background:#50c5a4;">
		<h1 class="center">共益夥伴推薦</h1>
		<br>
		<br>
		<template v-if="$root.rwd < 2">
			<template v-for="(item, i) in list">
				<Card :data="item" :key="i" />
			</template>
		</template>
		<Siema ref="siema" v-else :data="list" :options="{ loop: true  }" control>
			<template v-slot="{slide}">
				<Card :data="slide" />
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
	</ContentBlock>
</template>

<script>
import Card from "./Card1.vue"
import Siema from "../Siema.vue";
export default {
	components: {
		Card,
		Siema,
	},
	data() {
		return {
			list: [{
				img: require("../../assets/partners/people1.png"),
				description: "「期待 DFC 臺灣能夠持續帶領社會看見並投入重要教育議題，加速社福界往下一個世代邁進，形塑更好的社會氛圍。能與 DFC 臺灣這麼優秀的機構一起合作是我們的榮幸，我們與有榮焉。」",
				who: "DFC 臺灣營運支持\n共益夥伴 陳永泰公益信託 總幹事王景賢",
			}, {
				img: require("../../assets/partners/people2.png"),
				description: "「由美國知名設計公司 IDEO 提出的設計思考，當中「以人為本」創意思考方式，有助於解決師生在學習歷程中遇到的問題，值得在教育系統中推動。 DFC 臺灣在常規教育系統內嘗試以輔助方式突破，逐漸影響現場老師教學方法，也成功進入數所學校的特色課程。期待 DFC 學習法能成為國小、國中社會科學教育中常態訓練的一環。」",
				who: "DFC 臺灣共益夥伴\nRC 公益創投  執行長 王俊凱",
			},{
				img: require("../../assets/partners/people3.png"),
				description: "「早在2019年起，英仕教育基金會便率先支持種子學校計畫，是因為我們都看到教育現場得從根本改變的需求，不只是學生、孩子身邊的大人，甚而整體教育文化也要一起。在不斷變動的時代，現在即是未來，更需要 DFC 臺灣這樣的組織，協力臺灣的孩子能夠勇敢自信地說出：『Yes, I CAN ! 』」",
				who: "長期支持 DFC 種子學校企業夥伴\n英仕教育基金會 謝辛美執行長",
			},],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	--var-indicator-color: black;
	.right,
	.left {
		top: 10% !important;
	}
}
</style>