<template>
	<section class="layout">
		<div ref="fixed-menu" id="fixed-menu">
			<template v-if="develop_mode">
				<div
					@click="visible = !visible"
					class="center"
					style="
						cursor: pointer;
						background: gray;
						color: #fff;
						padding: 0.5em;
					"
				>
					按我顯示隱藏 menu
				</div>
				<el-collapse-transition>
					<div
						v-show="visible"
						class="col"
						align-x="center"
						gutter="10"
						style="background: lightgray; padding: 1em"
					>
						<template v-for="(item, i) in routerList">
							<router-link
								:to="`/${item.path}`"
								:key="i"
								@click.native="visible = false"
								>{{ item.path }}</router-link
							>
						</template>
					</div>
				</el-collapse-transition>
			</template>
			<navbar ref="navbar" :class="{ shadow }" />
		</div>
		<main>
			<transition name="el-fade-in" mode="out-in">
				<router-view />
			</transition>
		</main>
		<foot />
		<el-backtop :bottom="20" :right="20">
			<img src="../assets/scroll-top.png" style="width: 2em" />
		</el-backtop>
	</section>
</template>

<script>
import navbar from "../components/navbar/default.vue";
import foot from "../components/footer/default.vue";

export default {
	components: {
		navbar,
		foot,
		BackToTop: () => import("../components/BackToTop.vue"),
	},
	data() {
		return {
			visible: false,
			shadow: false,
			paddingTop: "",
			develop_mode: process.env.NODE_ENV == "developmen",
		};
	},
	computed: {
		routerList() {
			return this.$router.options.routes
				.find((item) => item.path == "/")
				.children.filter((item) => !item.path.includes(":"));
		},
	},
	methods: {
		handleScroll(e) {
			if (!this.shadow && window.scrollY > 10) this.shadow = true;
			else if (this.shadow && window.scrollY < 10) this.shadow = false;
		},
		init(e) {
			this.paddingTop =
				this.$refs["fixed-menu"] &&
				this.$refs["fixed-menu"].offsetHeight + "px";
			document.body.style = "padding-top:" + this.paddingTop;
		},
	},
	mounted() {
		this.init();
		window.addEventListener("resize", this.init);
		window.addEventListener("scroll", this.handleScroll);
		this.$gtm.trackView(
			this.$route.name || this.$route.path,
			this.$route.path
		);
	},
	beforeRouteUpdate(to, from, next) {
		this.$gtm.trackView(to.name || to.path, to.path);
		next();
	},
};
</script>

<style lang="scss" scoped>
.layout {
	background: $background;
}
main {
	overflow: auto;
	overflow-x: hidden;
}
#fixed-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	background: #fff;
}
::v-deep #register-btn {
	div:first-child {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0px 10px 2em 0;
		border-color: transparent #fdeeb6 transparent transparent;
	}
	a {
		background: #fdeeb6;
		color: #606266;
		padding: 0 5px;
	}
}
</style>
