<template>
	<footer class="col">
		<img
			src="../../assets/footer.png"
			width="100%"
			style="user-select: none"
		/>
		<section id="footer-content">
			<div
				class="row-grid"
				align-y="center"
				style="max-width: 1100px; margin: auto; width: 100%"
			>
				<div class="col" gutter="15" align-x="left">
					<router-link to="/">
						<img src="../../assets/logo.png" width="190" />
					</router-link>
					<p>
						地址：<a
							href="https://g.page/DFCTaiwan"
							target="_blank"
							class="underline"
						>
							臺北市中正區紹興北街31巷15弄5號
						</a>
						<br />電話：<a
							href="tel:02-2368-1310"
							class="underline"
						>
							02-2368-1310
						</a>
						<br />Email：<router-link
							to="/join-us#section5"
							class="underline"
						>
							service@dfctaiwan.org
						</router-link>
					</p>
					<div class="row" gutter="10">
						<template v-for="(item, i) in socialList">
							<a :href="item.link" target="_blank" :key="i">
								<img :src="item.icon" width="30" />
							</a>
						</template>
					</div>
				</div>
				<table
					v-if="desktopMode"
					style="flex: 1"
					:class="{ desktop: desktopMode }"
				>
					<tr>
						<td rowspan="2">
							<span>關於協會</span>
							<LinkList :list="aboutList" />
						</td>
						<td rowspan="2">
							<span>DFC 挑戰與分享季</span>
							<LinkList :list="challengeList" />
						</td>
						<td>
							<span>DFC 種子學校</span>
							<LinkList :list="schoolList" />
						</td>
						<td>
							<span>服務預約意願蒐集表</span>
							<LinkList :list="inviteList" />
						</td>
						<td>
							<span>支持協會</span>
							<LinkList :list="supportList" />
						</td>
					</tr>
					<tr>
						<td>
							<span>DFC 種子教師</span>
							<LinkList :list="teacherList" />
						</td>
						<td>
							<span>教學資源</span>
							<LinkList :list="resourceList" />
						</td>
						<td>
							<span>瞭解更多</span>
							<LinkList :list="readmoreList" />
						</td>
					</tr>
				</table>

				<table v-else style="width: 100%">
					<tr>
						<td colspan="2">
							<b>瞭解更多</b>
						</td>
					</tr>
					<tr>
						<template v-for="(sublist, i) in mobileList">
							<td :key="i">
								<LinkList :list="sublist" />
							</td>
						</template>
					</tr>
					<tr>
						<td colspan="2">
							<DfcButton
								theme="red"
								small
								href="https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=5&utm_source=officailwebsite&utm_medium=cpc&utm_campaign=2023donation"
								target="_blank"
							>
								捐款支持
							</DfcButton>
						</td>
					</tr>
				</table>

				<table style="width: 100%">
					<tr>
						<td colspan="6">
							<hr />
						</td>
					</tr>
					<tr>
						<td colspan="6">
							<p>全球認證與協力夥伴</p>
							<div
								class="row-grid"
								align-y="center"
								gutter-x="20"
								gutter-y="20"
							>
								<img src="../../assets/logo1.png" width="95" />
								<img src="../../assets/logo2.png" width="100" />
								<img src="../../assets/logo3.png" width="100" />
								<img src="../../assets/logo4.png" width="110" />
								<img src="../../assets/logo5.png" width="80" />
								<img src="../../assets/logo6.png" width="110" />
								<img src="../../assets/logo7.png" width="110" />
								<img src="../../assets/logo8.png" width="130" />
								<img src="../../assets/logo9.png" width="70" />
								<img src="../../assets/logo10.png" width="70" />
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="6">
							<hr />
						</td>
					</tr>
					<tr>
						<td colspan="6">
							<small
								class="row-grid"
								align-x="between"
								align-y="top"
								gutter-x="20"
							>
								<div
									class="row-grid"
									align-y="top"
									gutter-x="20"
								>
									<p>
										發票捐贈碼｜2721（二心二力）
										<br />統一編號｜26398814
									</p>
									<p>
										法人登記｜登記簿第94冊第49頁第2077號
										<br />許可證書｜臺內社字第1000214388號函
									</p>
								</div>
								<div class="col">
									<router-link
										class="underline"
										to="/privacy"
									>
										會員條款
									</router-link>
									<router-link
										class="underline"
										to="/copyright"
									>
										著作權聲明
									</router-link>
								</div>
							</small>
						</td>
					</tr>
					<tr>
						<td colspan="6">
							<p>
								<small>
									©
									{{ new Date().getFullYear() }}
									社團法人臺灣童心創意行動協會&emsp;All Rights
									Reserved.
								</small>
							</p>
						</td>
					</tr>
				</table>
			</div>
		</section>
		<el-dialog
			:visible="dialogVisible"
			:width="$root.rwd < 2 ? '600px' : '100%'"
			:show-close="false"
			center
			destroy-on-close
		>
			<template slot="title">
				<img
					src="../../assets/icon-microphone.svg"
					width="100"
					style="margin: auto"
				/>
				<h1>訪談、採訪邀約</h1>
			</template>
			<template v-if="dialogVisible">
				<p style="margin: 0">
					感謝各位媒體朋友對於 Design for Change, Taiwan
					的關注，我們很樂意與您分享 DFC
					的理念以及孩子們改變世界的故事，但是由於近期協會接收的採訪邀約漸漸變多，需要請您先協助我們填寫以下表單，
					並提供我們採訪綱要，便於讓我們理解貴單位的採訪需求，我們會在上班時間儘快與您聯繫，若等候過久，也請您來電不吝提醒我們。
					<br />（02）2368-1310
					<br />
					<br />同時，如果您對孩子的DFC故事有興趣的話，也許可引介您採訪有參加
					DFC 挑戰的老師和孩子們，他們才是真正的主角唷！:D
					<br />
					<br />再一次謝謝您！
					<br />
					<br />除突發新聞事件之外，讓我們邀請您於預計採訪日期前10～15個工作天提出採訪邀約，我們會更有充裕的時間調整受訪人力。
					<br />另外，我們也希望能夠於採訪露出後，麻煩貴單位郵寄一份成果，給我們留作紀錄（光碟、雜誌等...）
					<br />
					<br />謝謝您 :)
				</p>
				<br />
				<media-interview-form
					@cancel="dialogVisible = false"
					@done="dialogVisible = false"
				/>
			</template>
		</el-dialog>
	</footer>
</template>

<script>
import ResizeObserver from "@/utils/ResizeObserver";
import MediaInterviewForm from "../MediaInterviewForm.vue";
import LinkList from "./link-list.vue";
export default {
	components: {
		MediaInterviewForm,
		LinkList,
	},
	data() {
		return {
			desktopMode: true,
			dialogVisible: false,
			socialList: [
				{
					icon: require("@/assets/social-ig.png"),
					link: "https://www.instagram.com/dfc.taiwan",
				},
				{
					icon: require("@/assets/social-fb.png"),
					link: "https://www.facebook.com/TYCAA.DFCTaiwan",
				},
				{
					icon: require("@/assets/social-yt.png"),
					link: "https://www.youtube.com/user/dfctaiwan/featured",
				},
				{
					icon: require("@/assets/social-medium.png"),
					link: "https://medium.com/design-for-change-taiwan",
				},
			],
			aboutList: [
				{ label: "最新消息", link: "/news" },
				{ label: "我們是誰", link: "/about" },
				{ label: "年度成果", link: "/about#section3" },
				{ label: "媒體報導", link: "/media-center" },
				{
					label: "部落格",
					link: "https://medium.com/design-for-change-taiwan",
				},
				{
					label: "邀約採訪",
					link: "https://form.jotform.me/82461826186463",
				},
				{ label: "加入我們", link: "/join-us" },
			],
			challengeList: [
				{ label: "關於 DFC 挑戰", link: "/dfc-challenge#section1" },
				{ label: "關於 DFC 挑戰分享季", link: "/dfc-challenge" },
				{ label: "參加辦法", link: "/dfc-challenge#section5" },
				{ label: "常見問題", link: "/dfc-challenge#section8" },
				{ label: "課程服務專線", link: "/dfc-challenge#section5" },
				{ label: "歷屆 DFC 挑戰故事", link: "/story" },
			],
			schoolList: [
				{ label: "認識 DFC 種子學校", link: "/dfc-school" },
				{ label: "認證計畫介紹", link: "/dfc-school#section1" },
				{ label: "申請資格", link: "/dfc-school#section4" },
			],
			inviteList: [
				{
					label: "填寫服務預約意願蒐集表",
					link: "https://form.jotform.com/240518470260450",
				},
			],
			supportList: [
				{ label: "成為共益夥伴", link: "/partners" },
				{
					label: "捐款支持",
					link: "https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=5&utm_source=officailwebsite&utm_medium=cpc&utm_campaign=2023donation",
				},
			],
			teacherList: [
				{ label: "認識 DFC 種子教師", link: "/dfc-teacher" },
				{ label: "認證計畫介紹", link: "/dfc-teacher#section1" },
				{ label: "申請資格", link: "/dfc-teacher#section4" },
			],
			resourceList: [
				{ label: "書籍著作", link: "/resources#section2" },
				{ label: "教學資源平台", link: "/resources#section3" },
				{ label: "教學社群", link: "/resources#section4" },
			],
			readmoreList: [
				{
					label: "捐款徵信",
					link: "https://drive.google.com/drive/folders/1STeBE7kXXUo2KuwnefMNVg2gJN_tYh8j",
				},
				// {
				// 	label: "財務報告",
				// 	link: "https://drive.google.com/drive/folders/1fBmyrPGRThZrTOMwRV0gY7R7Wx6XWSPO",
				// },
			],
			mobileList: [
				[
					{ label: "最新消息", link: "/news" },
					{ label: "年度成果", link: "/about#section3" },
					{ label: "媒體報導", link: "/media-center" },
					{
						label: "部落格",
						link: "https://medium.com/design-for-change-taiwan",
					},
				],
				[
					{ label: "服務預約意願蒐集表", link: "/speech-workshop" },
					{ label: "DFC 種子學校", link: "/dfc-school" },
					{ label: "DFC 挑戰分享季", link: "/dfc-challenge" },
					{ label: "DFC 種子教師", link: "/dfc-teacher" },
					{ label: "教學資源", link: "/resources#section3" },
				],
			],
		};
	},
	mounted() {
		ResizeObserver(document.body, {
			onResizing: ({ width, height }) => {
				if (width < 768 && this.desktopMode) {
					this.desktopMode = false;
				} else if (width >= 768 && !this.desktopMode) {
					this.desktopMode = true;
				}
			},
		});
	},
};
</script>

<style lang="scss" scoped>
footer {
	width: 100%;
	position: relative;

	table {
		border-collapse: separate;
		border-spacing: 1em;
		flex-shrink: 0;

		td {
			vertical-align: top;
		}

		&.desktop td {
			white-space: nowrap;
		}
	}
}
#footer-content {
	font-size: 0.875em;
	background: #fff;
	padding: 5%;
}
</style>
