<template>
	<section>
		<header class="row-grid" align-y="center" align-x="between" gutter-y="20">
			<h2 style="margin:0;">{{ current.label }}（{{ current.session }}年）</h2>
			<div class="row" align-y="center">
				<b style="white-space:nowrap;">選擇屆數：</b>
				<el-select v-model="currentSession">
					<el-option
						v-for="(item, i) in sessionOptions"
						:key="i"
						:value="item.session"
						:label="`${item.session}（${item.label}）`"
					/>
				</el-select>
			</div>
		</header>
		<br />
		<br />
		<div ref="scale-anime" class="row-grid" align-x="center">
			<template v-for="(item, i) in currentList">
				<div class="profile-wrapper rwd-s-6" :key="i">
					<div class="profile col" align-x="center" gutter="20">
						<div class="avatar-wrapper" v-loading="loadingStates[i]" element-loading-background="rgba(255, 255, 255, 0.7)">
							<img
								:src="item.avatar"
								class="avatar"
								@load="loadingStates[i] = false"
							/>
						</div>
							<b style="font-size:1.5em; text-align:center;font-family: sans-serif;font-weight: 700;">
							{{ item.name }} 丨 {{ item.school }}
							</b>
						<small v-if="item.tags">{{ item.tags }}</small>
						<ul>
							<li v-if="item.opinion">
								<b>教師簡介：</b>
								<pre>{{ item.opinion }}</pre>
							</li>
							<li v-if="item.experiences">
								<b>DFC 相關資歷{{ item.DFCyear?`(${item.DFCyear}年)`:'' }}：  </b>
								<ul>
									<li v-for="experience in item.experiences" :key="experience" style="white-space: pre-line;">
										{{ experience }}
									</li>
								</ul>
							</li>
							<li v-if="item.subject">
								<b>職稱：</b>
								{{ item.subject }}
							</li>
							<li v-if="item.masterpiece">
								<b>代表故事：</b>
								<ul>
									<li v-for="{ title, link } in item.masterpiece" :key="title">
										<el-link :href="link" type="primary">
											{{ title }}
										</el-link>
									</li>
								</ul>
							</li>
							<li v-if="item.news">
								<b>相關報導：</b>
								<ul>
									<li v-for="news in item.news" :key="news.link">
										<el-link :href="news.link" type="primary" target="_blank">
											{{ news.title }}
										</el-link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</template>
		</div>
		<br />
		<template v-if="$root.rwd < 3">
			<div class="center">
				<DfcButton
					theme="red"
					round
					arrow
					href="https://drive.google.com/file/d/1LJCFtqzUJF4HyxkTcAYVW3VNszddQVkd/view"
					target="_blank"
					>下載 DFC 種子教師簡章</DfcButton
				>
			</div>
		</template>
		<DfcButton
			v-else
			id="become-teacher-fixed"
			theme="darkblue"
			href="https://drive.google.com/file/d/1LJCFtqzUJF4HyxkTcAYVW3VNszddQVkd/view"
			target="_blank"
			>下載 DFC 種子教師簡章</DfcButton
		>
	</section>
</template>

<script>
import { scaleAnime } from "../../libs/anime";
import list from "./list.json";
export default {
	data() {
		return {
			currentSession: new Date().getFullYear(),
			loadingStates: {},
		};
	},
	computed: {
		sessionOptions() {
			return (
				list.map(({ session, label }) => ({ session, label })) || new Array()
			);
		},
		current() {
			return (
				list.find((item) => item.session == this.currentSession) || new Object()
			);
		},
		currentList() {
			return this.current?.list?.map((item) => {
				return {
					...item,
					avatar: require(`@/assets/teacher-list/${this.currentSession}/${item.avatar}`),
				};
			});
		},
	},
	watch: {
		currentSession() {
			// Reset loading states when session changes
			if (this.current?.list) {
				this.loadingStates = this.current.list.reduce((acc, _, index) => {
					acc[index] = true;
					return acc;
				}, {});
			}
			scaleAnime(this.$refs["scale-anime"].children);
		},
	},
	mounted() {
		this.currentSession = list[0].session;
		// Initialize loading states
		if (this.current?.list) {
			this.loadingStates = this.current.list.reduce((acc, _, index) => {
				acc[index] = true;
				return acc;
			}, {});
		}
		scaleAnime(this.$refs["scale-anime"].children);
	},
};
</script>

<style lang="scss" scoped>
.profile-wrapper {
	padding: 2em 3%;

	@media screen and (min-width: 426px) {
		&:nth-child(even) {
			margin-top: 30px;
			margin-bottom: -30px;
		}
	}
}

.profile {
	background: #fff;
	border-radius: 5px;
	border: solid 0.5px #dc3d3c;
	height: 100%;
	padding: 7%;

	.avatar-wrapper {
		margin-top: -4em;
		position: relative;
		width: 167px;
		height: 167px;

		.avatar {
			border-radius: 100%;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	ul {
		color: #4b4b4b;

		li {
			margin-bottom: 5px;
		}
	}
	b {
		white-space: nowrap;
		font-weight: 500;
		color: #000;
	}
	pre {
		margin: 0;
		font-size: 0.9em;
	}
}
#become-teacher-fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	border-radius: 0;
}
</style>
