<template>
	<ContentBlock style="padding: 3em 0 0;">
		<h1 class="center">DFC 臺灣 的共益夥伴，一起構築教育生態系</h1>
		<br>
		<template v-if="$root.rwd < 2">
			<template v-for="(item, i) in list">
				<Card :data="item" :key="i" />
			</template>
		</template>
		<Siema ref="siema" v-else :data="list" :options="{ loop: true  }" control>
			<template v-slot="{slide}">
				<Card :data="slide" />
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
	</ContentBlock>
</template>

<script>
import Card from "./Card.vue"
import Siema from "../Siema.vue";
export default {
	components: {
		Card,
		Siema,
	},
	data() {
		return {
			list: [{
				img: require("../../assets/partners/img1.jpg"),
				title: "支持孩童分享舞台｜與臺灣囡仔一起閃耀舞台",
				description: "每個孩子都值得擁有自己的舞台，綻放屬於他們的光芒！支持 DFC 挑戰分享季與全球孩童年會，讓我們一同為臺灣的孩子打造一個可以練習、挑戰自我的成長舞台。更多孩子能在分享的過程中，探索自我，更能自信地向世界展現自己。",
				cases: "<p>感謝全漢企業股份有限公司及研華文教基金會多年贊助我們打造孩童分享舞台，並支持臺灣孩童代表每年前往全球孩童年會邁向國際。站上舞台不僅能讓孩子明白每一段努力都有被肯定的價值，更能與臺灣社會、國際世界建立更多連結，閃耀國際。企業更能與我們的共創過程，展現社會責任，發揮國際影響力。<br><br>了解更多站上舞台的孩子有什麼改變？<a href='https://youtu.be/si77aMxCkd4?feature=shared' class='underline' target='_blank'>請見 <<2024 DFC挑戰分享季>></a><br>了解世界首創由孩子主導的全球孩童年會，<a href='https://youtu.be/k27vUeCaFSw?feature=shared' class='underline' target='_blank'>請見 <<2024 全球孩童年會>></a></p>"
			}, {
				img: require("../../assets/partners/img2.jpg"),
				title: "支持 DFC 臺灣推廣永續議題｜在校園中開啟永續對話",
				description: "想要與 DFC 臺灣共創、將更多永續議題帶進校園嗎？透過支持 DFC 永續教材計畫，我們將把各式領域的永續議題帶入教學現場，與企業一同透過教材教具、教育論壇及跨領域工作坊等平台，在校園中開啟永續對話。讓企業的永續理念更加擴展到教育與未來世代，實現永續影響力的擴大。",
				cases: "<p>花仙子教育基金會、童顏有機股份有限公司贊助支持 DFC 臺灣研發與印製《日日挑戰》教具，結合巡迴工作坊讓永續議題真正落實在教學日常，與 DFC 臺灣一起成為永續推手。</p>"
			}, {
				img: require("../../assets/partners/img3.jpg"),
				title: "支持教育工作者增能｜共同推動優質教育現場",
				description: "當今學校的挑戰，除了教師短缺，更在於如何培育能啟發學生的教學者。教師不僅是知識的傳遞者，更是引導價值觀、陪伴孩子成長的關鍵夥伴。\n支持 DFC 師『陪』計劃，將幫助第一線教師掌握多元創新教育方法，結合 DFC 學習法課程設計，點燃孩子的學習熱情與潛力，打造更優質教育現場。",
				cases: "<p>感謝台新青少年基金會、台南企業文化藝術基金會與 DFC 臺灣的合作，支持我們以清楚具體的課程架構結合永續、在地議題，讓北中南各區的教學者能輕鬆掌握課堂改變的有效方法。</p>"
			}, {
				img: require("../../assets/partners/img4.jpg"),
				title: "支持種子學校持續孵化｜成為教育的播種者",
				description: "最長久的改變是讓永續在全臺各校持續發生！支持 DFC 種子學校孵化計畫，一同協力將系統性方式將 DFC 學習法導入學校課程，讓教師能建立專業團隊、建立創新教學心態，並有具體教學鷹架設計孩子的適性課程，最終並進入課堂見證歷程改變，成為教育的圈內人。",
				cases: "<p>英仕教育基金會、國富文教基金會多年與我們攜手打造 DFC 種子學校，透過長期的陪伴與共同備課，將改變的影響力持續注入於教育現場。<br><br>了解更多 DFC 臺灣如何孵化種子學校：<a href='https://www.youtube.com/watch?v=Oi-mnzdDvGM' class='underline' target='_blank'>請見 <<2024 DFC 種子學校孵化計畫>></a></p>"
			}, {
				img: require("../../assets/partners/img5.jpg"),
				title: "讓員工、團體成員一起見證孩童的力量",
				description: "DFC 臺灣邀請企業內部員工以定期定額支持，透過講座分享，與企業夥伴一同看見孩童的多元潛力，創造擁抱失敗與挫折的企業文化。",
				cases: "<p>感謝智邦科技除員工多年支持外，也邀請 DFC 臺灣透過設計思考員工內訓工作坊打造企業文化。</p>"
			},],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	--var-indicator-color: black;
	.right {
		right: 30% !important;
		// transform: none !important;
	}
	.left {
		left: 30% !important;
		// transform: none !important;
	}
	.right,
	.left {
		top: 100% !important;
	}
}
</style>